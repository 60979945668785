//
// General
//

@mixin mediaQueryLG() {
	@media screen and (min-width: 1200px) {
		@content;
	}
}

@font-face {
	font-family: "Futura Std Book";
	src: url("/assets/fonts/main/Futura Std Book_1.otf") format("otf");
}

//Sidebar

$sidebar-width: 16rem !default;
$sidebar-text-color: #ecf0f1;
$sidebar-bg-color: #333;
$compact-sidebar-width: 70px !default;

//  Header
$header-bg-color: #f0f2f7;
$header-font-color: #000000;

//Md card
$mat-card-padding-top-bottom: 0.875rem;
$mat-card-padding-left-right: 1.5rem;
$mat-card-bg-color: #ffffff;
$mat-card-title-font-color: #37474f;
$mat-card-hover-effect: 0 0 10px rgba(0, 0, 0, 0.2);
//mat-icon

$mat-card-icon-color: #868e96;

//Main Container
$container-bg: #f0f2f7;
//Rounded Icons
$highlight-icons: #ffffff;
$highlight-icon-color: #37474f;

// Typography
//

$font-color: #37474f;
//Meta post
$meta-post-color: #0a0000;
$meta-post-font-weight: bold;
//Breadcrumb
$breadcrumb-font-size: 0.875rem;
$breadcrumb-font-color: #37474f;

//Mat list hover bg
$mat-list-hover-bg-color: rgba(0, 0, 0, 0.2);
$html-font-size: 16px;
$mat-font-family: 'Futura Std Book', sans-serif;
$font-size-base: 1rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

//Heading Font Size

$font-size-h1: 2rem !default;
$font-size-h2: 1.625rem !default; //26px
$font-size-h3: 1.375rem !default; //22px
$font-size-h4: 1.125rem !default; //18px
$font-size-h5: 0.875rem !default; //14px
$font-size-h6: 0.75rem !default; //12px
//Heading Font color

$h1-font-color: inherit;
$h2-font-color: inherit;
$h3-font-color: inherit;
$h4-font-color: inherit;
$h5-font-color: inherit;
$h6-font-color: inherit;

$headings-margin-bottom: calc(1rem / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

//
// Radius
//
//Mat -card
$border-radius-base: 8px;

//overlay-content
$overlay-content-overlay: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
$overlay-content-text: #ffffff;

//timeline
$card-elems-radius: 8px;

// Date picker override styles
$main-color-time-picker: #1565c0;
$box-shadow-buttons: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
	0px 1px 5px 0px rgba(0, 0, 0, 0.12);

button[id^="timepicker-item-id"].active {
	background-color: $main-color-time-picker !important;
}

.time-picker-clock-arrow,
.time-picker-clock-origin {
	background: $main-color-time-picker !important;

	span {
		background: $main-color-time-picker !important;
	}
}

.time-picker-footer {
	button.atp-ref-dialog-close {
	}

	button {
		font-size: 14px !important;
		line-height: 36px !important;
		padding: 0px 16px !important;
		border-radius: 4px !important;

		&.atp-ref-dialog-close {
			background-color: $main-color-time-picker !important;
			color: white;
			box-shadow: $box-shadow-buttons;
		}

		&:not(.atp-ref-dialog-close) {
			background-color: white !important;
			color: #000 !important;
			box-shadow: $box-shadow-buttons;
		}
	}
}

datatable-scroller {
	width: 100% !important;
}

jt-horario .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
	display: block;
}

jt-horario .cal-week-view .cal-hour-segment.cal-hour-start .cal-time {
	display: none;
}
