/*========|Scaffolding|=========*/
html {
    font-size: $html-font-size;
}

@media(max-width:1366px) {
    html {
        font-size: $html-font-size - 1;
    }
}

@media(max-width:1279px) {
    html {
        font-size: $html-font-size - 1;
    }
}

@media(max-width:959px) {
    html {
        font-size: $html-font-size - 2;
    }
}

html,
body {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    color: $font-color;
    padding: 0;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    line-height: 1.5;
    font-family: $mat-font-family;
    &.pace-done {
        background: #6b6b6b;
    }
}

body .mat-card {
    font-family: $mat-font-family;
    background-color: $mat-card-bg-color;
}

body .mat-list .mat-list-item .mat-list-item-content,
.mat-nav-list .mat-list-item .mat-list-item-content,
.mat-toolbar,
.mat-button,
.mat-fab,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-checkbox,
.mat-input-element,
.mat-input-container,
.mat-tab-labels,
.mat-tab-label,
.mat-toolbar,
.mat-menu-item,
.mat-simple-snackbar,
.mat-select-content {
    font-family: $mat-font-family !important;
}

[tabindex='-1']:focus {
    outline: none;
}

select,
button,
textarea,
input {
    vertical-align: baseline;
}

html,
body {
    &[dir=rtl],
    &[dir=ltr] {
        unicode-bidi: embed
    }
}

bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override;
}

.mat-card.settings-panel {
    position: fixed;
    bottom: 6px;
    right: 6px;
    width: 300px;
    z-index: 9;
}

body .mat-card {
    color: $font-color;
    // h3{
        // color: mat-color($theme, $primary);
    // }
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

