@tailwind base;
@tailwind components;
@tailwind utilities;





$theme-colors: (
	"primary": #f29100
);

// @import "../node_modules/bootstrap/scss/bootstrap";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import 'node_modules/@angular/material/theming';
// Plus imports for other components in your app.
@import 'node_modules/ngx-toastr/toastr';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!


$ef-orange: (
	50: #fdf2e0,
	100: #fde0b3,
	200: #f8c87e,
	300: #f6b14b,
	400: #f4a125,
	500: #f29100,
	600: #ee8600,
	700: #e87600,
	800: #e26700,
	900: #d84d00,
	1000: #d1192e,
	contrast: (50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		1000 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff)
);
$ef-orange-another: (
	50: #fdf2e0,
	100: #fde0b3,
	200: #f8c87e,
	300: #f6b14b,
	400: #f4a125,
	500: #f29100,
	600: #ee8600,
	700: #e87600,
	800: #e26700,
	900: #d84d00,
	1000: #d1192e,
	contrast: (50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		1000 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff)
);
$ef-black: (
	50: #f8f8f8,
	100: #eaeaea,
	200: #e4e4e4,
	300: #d3d3d3,
	400: #aeaeae,
	500: #8e8e8e,
	600: #666666,
	700: #535353,
	800: #353535,
	900: #151515,
	1000: #000000,
	contrast: (50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		1000 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff)
);
$light-color: #ffffff;
$dark-color: #000000;
$accept-button: #36bb40;
$danger-button: #d1192e;
$cancel-button: #8e8e8e;
$green-color: (
	100: #ccffcc,
	500: #36bb40,
	700: #0d4d0d,
	contrast: (50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff)
);
$edufactoring-cliente-primary: mat-palette($ef-orange);
$edufactoring-cliente-accent: mat-palette($ef-black, 500);
$edufactoring-cliente-accent: mat-palette($mat-pink, A200, A100, A400);
$edufactoring-cliente-warn: mat-palette($mat-red);
$edufactoring-cliente-theme: mat-light-theme($edufactoring-cliente-primary,
		$edufactoring-cliente-accent,
		$edufactoring-cliente-warn);

@include mat-core();
$dark-primary: mat-palette($mat-blue, 900);
$dark-accent: mat-palette($mat-teal, 600);
$dark-success: mat-palette($mat-green, 500);
$dark-warn: mat-palette($mat-pink, 400);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);
$primary: mat-palette($ef-orange, 500);
// $dark: mat-palette($ef-black, 500);

$mat-red: mat-palette($ef-orange-another, 500);
$accent: mat-palette($ef-black, 1000);
$warn: mat-palette($mat-red, 600);
$success: mat-palette($green-color, 500);
$theme: mat-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
$my-primary: mat-color($ef-orange, 500);
$my-accent: mat-color($accent);
$my-warn: mat-color($warn);
$my-success: mat-color($success);
$my-dark-primary: mat-color($dark-primary);
$my-dark-accent: mat-color($dark-accent);
$my-dark-warn: mat-color($dark-warn);
$my-dark-success: mat-color($dark-success);
@include angular-material-theme($edufactoring-cliente-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.app-dark {
	.primary-bg {
		background: $my-dark-primary !important; // color:$light-color !important;
	}

	.primary-text {
		color: $my-dark-primary !important;
	}

	.success-bg {
		background: $my-dark-success !important; // color:$light-color !important;
	}

	.success-text {
		color: $my-dark-success !important;
	}

	.warn-bg {
		background: $my-dark-warn !important; // color:$light-color !important;
	}

	.warn-text {
		color: $my-dark-warn !important;
	}

	.accent-text {
		color: $my-dark-accent !important;
	}

	.accent-bg {
		background: $my-dark-accent !important; // color:$light-color !important;
	}
}

.primary-bg {
	background: $my-primary !important;
	color: $light-color !important;
}

.primary-text {
	color: $my-primary !important;
}

.success-bg {
	background: $my-success !important;
	color: $light-color !important;
}

.success-text {
	color: $my-success !important;
}

.warn-bg {
	background: #464a4c !important;
	color: #f3f5f6 !important;
}

.warn-text {
	color: $my-warn !important;
}

.accent-text {
	color: $my-accent !important;
}

.accent-bg {
	background: $my-accent !important;
	color: $light-color !important;
}

.dark {
	color: $dark-color !important;
}

.primary-border {
	border: 1px solid $my-primary !important;
}

.primary-text {
	color: $my-primary !important;
}

mat-dialog-container button[type=button].mat-raised-button {
	background: $cancel-button;
	color: #ffffff;
}

mat-dialog-container button[type=submit][color=primary].mat-raised-button:not([disabled]) {
	background: $accept-button;
	color: #ffffff;
}

.accept-button {
	background: $accept-button;
	color: #ffffff;
}

.cancel-button {
	background: $cancel-button;
	color: #ffffff;
}

.placeholder {
	color: #323232 !important;
}

.active-status-bg,
.aprobado-status-bg {
	background-color: #28a745;
	color: white;
}

.pendiente-status-bg {
	background-color: #ffc107;
	color: white;
}

.inactive-status-bg {
	background-color: #7f8c8d;
	color: white;
}

.anulado-status-bg {
	background-color: #d70606;
	color: white;
}

.desembolsado-status-bg {
	background-color: #36bb40;
	color: white;
}

.accept-text {
	color: $accept-button;
}

.danger-text {
	color: $danger-button;
}

.cancel-text {
	color: $cancel-button;
}

.chip {
	display: inline-block;
	padding: 0 10px;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	border-radius: 25px;
	background-color: #e4e0e0;
}

.chip img {
	float: left;
	margin: 0 10px 0 -25px;
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.text-break {
	word-break: break-word !important;
	overflow-wrap: break-word !important;
}

.placeholder {
	background-color: transparent !important;
}

@import "./assets/scss/style.scss";


@import "assets/scss/mixins/bi-app/bi-app-ltr";
@import "assets/scss/app.variables";
@import "assets/scss/mixins";
@import "assets/scss/core";
@import "assets/scss/material";
@import "assets/scss/components";
@import 'assets/scss/utilities/utilities.scss';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

.ef-modal-container {
	min-width: 80vw !important;
	max-height: 90vh !important;
	overflow-y: auto !important;
	box-sizing: border-box !important;
	max-width: calc(100% - 50px) !important;
}

.mat-checkbox-layout {
	white-space: normal !important;
}

.login-logo {
	width: 200px;
}

.bg-bl-login {
	background-color: #171715 !important;
}
.mat-menu-panel{
	margin:1rem;
	max-width: fit-content !important;
 }
 .header-color-primary,.header-color-warning,.header-color-accent,.header-color-green {
	.gene-header-toolbar {
	   .navbar-brand, .mat-icon  {
		   color:$light-color;
	   }
		.mat-form-field-wrapper {
		   .mat-form-field-label, .mat-toolbar,.mat-input-element {
			  color: $light-color !important;
		   }
		   .mat-form-field-underline,  .mat-form-field-ripple{
			   background-color:$light-color;
		   }
		}
		ul .breadcrumb-item{
			color:$light-color;
		}
	 }
 }
 .breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/");
	padding-right: 0.5rem;

}
.d-none {
	display: none !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #353535 !important;
}

.bg-primary {
  background-color: var(--color-global) !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #6c757d !important;
}

.mat-divider {
  opacity: 0.5 !important;
}
.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 0px;
}
